import React, {useEffect, useRef, useState} from 'react'
import {DaySuccintInfos} from '../../../types/pickDay/DaySuccintInfos'
import {OneDayContainer} from '../../common/one-day/one-day-container'
import {transRadarData} from '../../common/sibsung-radar/SibSungRadar'
import {AppState} from '../../../store/AppState'
import {Dispatch} from 'redux'
import {connect} from 'react-redux'
import {
  scrollToPickBox,
  pickDayBox,
  graphBox,
} from '../pick-birthday.module.scss'
import {Element, scroller} from 'react-scroll/modules'
import moment from 'moment-timezone'
import {ONE_HOUR_IN_MILLI} from '../../../constants'
import EmptySpace from '../../common/EmptySpace'
import {VictoryRadar} from '../../common/sibsung-radar/VictoryRadar'
import {
  OhHangPieChart,
  transformOhHangData,
} from '../../common/ohhang-pie-chart/ohhang-pie-chart'
import {throttle} from 'lodash'
import {TotalScores} from '../../../types/saJoo/SuccinctInfo'
import {VictoryLineGraph} from '../../common/score-line-graphs/VictoryLineGraph'
import {CircleBtn, Divider} from '../../../styles/common'
import {Grid} from 'react-feather'

interface Props {
  data: DaySuccintInfos[]
  // fetchInfos: () => void;
}

export const PickDayBox: React.FC<Props> = ({data}) => {
  const [radar, setRadar] = useState([0, 0, 0, 0, 0])
  const [ohHang, setOhHang] = useState([1, 1, 1, 1, 1])
  const [review, setReview] = useState(['', '', '', '', ''])
  const [time, setTime] = useState(Date.now)
  const pickBoxBottomRef = useRef<HTMLDivElement>(null)
  const [scrollToPickBoxShow, setScrollToPickBoxShow] = useState(false)
  const [boy, setBoy] = useState<TotalScores>()
  const [girl, setGirl] = useState<TotalScores>()
  const controlScrollToPickBoxShow = throttle((e) => {
    if (
      pickBoxBottomRef.current &&
      pickBoxBottomRef.current.getBoundingClientRect().top < 90
    ) {
      setScrollToPickBoxShow(true)
    } else {
      setScrollToPickBoxShow(false)
    }
  }, 200)
  useEffect(() => {
    window.addEventListener('scroll', controlScrollToPickBoxShow)
    return () => {
      window.removeEventListener('scroll', controlScrollToPickBoxShow)
    }
  })

  return (
    <div style={{width: '100%'}}>
      {scrollToPickBoxShow && (
        <CircleBtn
          onClick={() =>
            scroller.scrollTo('pick-box', {
              duration: 200,
              delay: 0,
              offset: -80,
              smooth: 'easeInOutQuart',
            })
          }
          className={scrollToPickBox}
        >
          <Grid color={'#7f7f7f'} />
        </CircleBtn>
      )}
      <div className={pickDayBox}>
        <div>
          <Element name={'pick-box'} />
          {data.map((val, index) => (
            <OneDayContainer
              key={index}
              data={val}
              onClick={(
                time,
                selectedRadar,
                selectedOhHang,
                review,
                boy,
                girl,
              ) => {
                if (process.env.NODE_ENV === 'production')
                  gtag('event', 'click_pick_birthday_box')
                setRadar(selectedRadar)
                setOhHang(selectedOhHang)
                setReview(review)
                setTime(time)
                setBoy(boy)
                setGirl(girl)
                if (window.innerWidth <= 750) {
                  scroller.scrollTo('after-click', {
                    duration: 500,
                    delay: 0,
                    offset: -80,
                    smooth: 'easeInOutQuart',
                  })
                }
              }}
            />
          ))}
          <div ref={pickBoxBottomRef}></div>
        </div>
        <div>
          <EmptySpace height={'20px'} />
          <Element name={'after-click'} />
          <div className={graphBox}>
            <h4>
              {moment.tz(time, 'Asia/Seoul').format('M월 D일 H시 m분')} -{' '}
              {moment
                .tz(time + 2 * ONE_HOUR_IN_MILLI, 'Asia/Seoul')
                .format('M월 D일 H시 m분')}
            </h4>
            <h3>종합 점수</h3>
            <div style={{width: 300, height: 300}}>
              <VictoryRadar data={transRadarData(radar)} />
            </div>
          </div>
          <div className={graphBox}>
            <h3>오행 분포</h3>

            <div style={{width: 300, height: 300}}>
              <OhHangPieChart data={transformOhHangData(ohHang)} />
            </div>
          </div>
        </div>
      </div>
      {/*<div className={"divider"}/>*/}
      <Divider />
      <h1>인생그래프</h1>
      <p>
        남자와 여자는 대운 방향이 다르기 때문에 사주가 같더라도 인생의 흐름이
        다릅니다. 남아는 파란색, 여아는 핑크색으로 인생그래프가 표시됩니다.
      </p>
      <h2>자아</h2>
      <p>{review[0]}</p>
      <div>
        {boy && girl && (
          <VictoryLineGraph
            title={'자아 인생그래프'}
            data={boy.egoScores}
            tickType={'year'}
            data2={girl.egoScores}
            label={'남아: 파란색 선'}
            label2={'여아: 핑크색 선'}
            order={1}
          />
        )}
      </div>

      <h2>활동</h2>
      <p>{review[1]}</p>
      <div>
        {boy && girl && (
          <VictoryLineGraph
            title={'활동 인생그래프'}
            data={boy.actScores}
            tickType={'year'}
            data2={girl.actScores}
            label={'남아: 파란색 선'}
            label2={'여아: 핑크색 선'}
            order={2}
          />
        )}
      </div>
      <h2>성취</h2>
      <p>{review[2]}</p>
      <div>
        {boy && girl && (
          <VictoryLineGraph
            title={'성취 인생그래프'}
            data={boy.wealthScores}
            tickType={'year'}
            data2={girl.wealthScores}
            label={'남아: 파란색 선'}
            label2={'여아: 핑크색 선'}
            order={3}
          />
        )}
      </div>
      <h2>명예</h2>
      <p>{review[3]}</p>
      <div>
        {boy && girl && (
          <VictoryLineGraph
            title={'명예 인생그래프'}
            data={boy.honorScores}
            tickType={'year'}
            data2={girl.honorScores}
            label={'남아: 파란색 선'}
            label2={'여아: 핑크색 선'}
            order={4}
          />
        )}
      </div>
      <h2>공부</h2>
      <p>{review[4]}</p>
      <div>
        {boy && girl && (
          <VictoryLineGraph
            title={'공부 인생그래프'}
            data={boy.studyScores}
            tickType={'year'}
            data2={girl.studyScores}
            label={'남아: 파란색 선'}
            label2={'여아: 핑크색 선'}
            order={5}
          />
        )}
      </div>
      <Divider />
    </div>
  )
}

const mapStateToProps = ({pickDay}: AppState) => ({
  // data: pickDay.pickBirthDayInfos
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  // fetchInfos: () => dispatch(fetchPickBirthDayInfos())
})

export default connect(mapStateToProps, mapDispatchToProps)(PickDayBox)
