import React from 'react'
import {
  VictoryArea,
  VictoryChart,
  VictoryGroup,
  VictoryTheme,
  VictoryPolarAxis,
  VictoryBar,
  VictoryLabel,
} from 'victory'

interface Props {
  data: Data[]
}

interface Data {
  category: string
  you: number
}

export const VictoryRadar: React.FC<Props> = ({data}) => {
  return (
    <div>
      <VictoryChart
        polar
        startAngle={162}
        endAngle={522}
        theme={VictoryTheme.material}
        style={{parent: {touchAction: 'auto'}}}
      >
        {/*<VictoryChart>*/}
        <VictoryGroup
          colorScale={['orange', 'tomato', 'gold']}
          style={{data: {fillOpacity: 0.2, strokeWidth: 2}}}
        >
          <VictoryArea
            labels={({datum}: {datum: {x: number; y: number}}) => datum.y}
            domain={{y: [0, 8]}}
            labelComponent={<VictoryLabel labelPlacement={'vertical'} />}
            data={data.reverse().map((val, i) => ({x: i, y: val.you}))}
          />
        </VictoryGroup>
        {data.map((val, i) => (
          <VictoryPolarAxis
            key={i}
            dependentAxis
            style={{
              axisLabel: {padding: 10, fontSize: '16px'},
              axis: {stroke: 'none'},
              grid: {
                stroke: 'grey',
                strokeStyle: {},
                strokeWidth: 0.1,
                opacity: 0.5,
              },
            }}
            tickLabelComponent={<VictoryLabel labelPlacement={'vertical'} />}
            labelPlacement="vertical"
            axisValue={i}
            label={val.category}
            tickFormat={(t) => ''}
            tickValues={[1, 2, 3, 4, 5, 6]}
          />
        ))}
        {/*<VictoryPolarAxis*/}
        {/*  labelPlacement="parallel"*/}
        {/*  tickFormat={() => ""}*/}
        {/*  style={{*/}
        {/*    axis: { stroke: "none" },*/}
        {/*    grid: { stroke: "grey", opacity: 0.5 }*/}
        {/*  }}*/}
        {/*/>*/}
      </VictoryChart>
    </div>
  )
}
