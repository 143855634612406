import React from 'react'
import {birthBox} from './pick-birthday.module.scss'
import PickDayBox from './pick-day/pick-day-box'
import {DaySuccintInfos} from '../../types/pickDay/DaySuccintInfos'
import {Element} from 'react-scroll/modules'
// @ts-ignore
import AdSense from 'react-adsense'
import {CircularProgress} from '@material-ui/core'
import SEO from '../seo'
import {Container, Divider} from '../../styles/common'
import MotherHood from '../common/MotherHood'
import SaJooAds from "../common/ads/sajoo-ads";

interface Props {
  data: DaySuccintInfos[]
  fetchInfo: () => void
  isLoading: boolean
}

export const PickBirthdayContainer: React.FC<Props> = ({
  data,
  fetchInfo,
  isLoading,
}) => {
  return (
    <div>
      <SEO
        title={'무료 출산 택일'}
        description={
          '오늘로부터 30일 동안의 각 시간별 사주 점수를 무료로 제공합니다. 점수가 높은 시간대를 골라 출산에 참고하시기 바랍니다.'
        }
      />
      <section className="pt-20 lg:pt-40 mb-4 mt-10">
        <div className={'container max-w-screen-xl mx-auto px-8 lg:flex'}>
          <div className="text-center lg:text-left lg:w-1/2">
            <h1 className="text-5xl lg:text-6xl xl:text-6xl font-bold leading-none">
              출산 택일
            </h1>
            {/*<h2 className="text-3xl lg:text-4xl font-semibold text-gray-500">무료 출산 택일</h2>*/}
            <p className="text-xl lg:text-2xl mt-6 font-light">
              오늘로부터 30일 동안의 각 시간별 사주 점수를 무료로 제공합니다.
            </p>
            <div className="mt-8 md:mt-12">
              <button
                className="block  mb-2 mx-auto lg:mx-0  w-64 py-3 bg-blue-400 hover:bg-blue-500 rounded text-white"
                onClick={() => {
                  if (!isLoading) fetchInfo()
                }}
              >
                {isLoading ? (
                  <CircularProgress color={'inherit'} size={20} disableShrink />
                ) : (
                  '30일간의 사주 점수 보기'
                )}
              </button>
            </div>
            <p className="mt-4 text-gray-600 font-thin">
              오늘의 운세, 일반 택일, 출산 택일 모두 무료입니다.
            </p>
          </div>
          <div className="lg:w-1/2">
            <div className="mx-auto max-w-2xl">
              <MotherHood />
            </div>
          </div>
        </div>
      </section>

      <Divider hidden />
      <Container>
        <Element name={'after-load'} />
        {/*<AdSense.Google*/}
        {/*  client="ca-pub-5894919272228059"*/}
        {/*  slot="8397580173"*/}
        {/*  style={{display: 'block'}}*/}
        {/*  format="auto"*/}
        {/*  responsive="true"*/}
        {/*  layoutKey="-gw-1+2a-9x+5c"*/}
        {/*/>*/}
        <SaJooAds/>
        {data.length > 0 && (
          <div style={{width: '100%'}}>
            <div className={'miniDivider'} />
            <p>
              짙은 파란색일수록 사주가 좋은 시간입니다. 클릭하면 종합 점수와
              오행 분포가 표시됩니다.
            </p>
            <div className={birthBox}>
              <PickDayBox data={data} />
            </div>
          </div>
        )}
      </Container>
      <Divider hidden />
    </div>
  )
}
