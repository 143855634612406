import React from 'react'

interface Data {
  category: string
  you: number
}

export function transRadarData(data: number[]): Data[] {
  const categories = ['자아', '활동', '성취', '명예', '공부']
  return data.map((val, i) => ({
    category: categories[i],
    you: val,
  }))
}
