export enum SibSung {
  ME,
  NULL,

  BI_GYUN,
  GUB_JAE,

  SIK_SIN,
  SANG_GWAN,

  JUNG_JAE,
  PYUN_JAE,

  JUNG_GWAN,
  PYUN_GWAN,

  JUNG_IN,
  PYUN_IN,
}
