import {Ji} from '../../enums/Ji'
import {Gan} from '../../enums/Gan'
import {EumYang} from '../../enums/EumYang'

//음양 리턴
export function getEumYang(input: Ji | Gan): EumYang {
  switch (input) {
    case Gan.GAB:
    case Gan.BYUNG:
    case Gan.MU:
    case Gan.GYUNG:
    case Gan.IM:
    case Ji.IN:
    case Ji.JIN:
    case Ji.SA:
    case Ji.SIN:
    case Ji.SUL:
    case Ji.HAE:
      return EumYang.YANG
    default:
      return EumYang.EUM
  }
}
