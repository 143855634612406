import React from 'react'
import {tooltip, oneTimeBox, tooltiptext} from './one-day.module.scss'
import moment from 'moment-timezone'
import {ONE_HOUR_IN_MILLI} from '../../../constants'

interface Props {
  time: number
  score: number
  onClick: () => void
}

//
// const color = ['rgba(0,79,255,1.0)', 'rgba(0,79,255,0.8)', 'rgba(0,79,255,0.6)',
//   'rgba(0,79,255,0.4)', 'rgba(0,79,255,0.2)'];

export const OneTimeBox: React.FC<Props> = ({time, score, onClick}) => {
  const color = `rgba(0,79,255,${(score / 6).toFixed(1)}`

  return (
    <div className={tooltip} onClick={() => onClick()}>
      <div className={oneTimeBox} style={{backgroundColor: color}}></div>
      <span className={tooltiptext}>
        {moment.tz(time, 'Asia/Seoul').format('HH:mm')}-
        {moment.tz(time + 2 * ONE_HOUR_IN_MILLI, 'Asia/Seoul').format('HH:mm')}
        <br />
        {score.toFixed(1)} 점
      </span>
    </div>
  )
}
