export enum SinSal {
  NULL,
  GUB,
  JAE,
  CHUN,
  JI,
  YUN,
  WOL,
  MANG_SIN,
  JANG_SUNG,
  BAN_AN,
  YUK_MA,
  YOUK_HAE,
  HWA_GAE,
}
