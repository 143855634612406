import {PickBirthdayContainer} from './pick-birthday-container'
import {AppState} from '../../store/AppState'
import {Dispatch} from 'redux'
import {fetchPickBirthDayInfos} from '../../store/pick-day/actions'
import {connect} from 'react-redux'

const mapStateToProps = ({pickDay}: AppState) => ({
  data: pickDay.pickBirthDayInfos,
  isLoading: pickDay.isLoading,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchInfo: () => dispatch(fetchPickBirthDayInfos()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PickBirthdayContainer)
