export enum Gan {
  NULL = 0,
  GAB,
  EUL,

  BYUNG,
  JUNG,

  MU,
  GI,

  GYUNG,
  SIN,

  IM,
  GYE,
}
