// 이 함수만 속해있는 위치가 backend와 다르다.
import {Ji} from '../../enums/Ji'
import {Gan} from '../../enums/Gan'
import {OhHang} from '../../enums/OhHang'

export function getOhHang(input: Ji | Gan | undefined): OhHang {
  switch (input) {
    case Gan.NULL:
      return OhHang.NULL

    case Gan.GAB:
    case Gan.EUL:
    case Ji.IN:
    case Ji.MYO:
      return OhHang.MOK

    case Gan.BYUNG:
    case Gan.JUNG:
    case Ji.SA:
    case Ji.OH:
      return OhHang.HWA

    case Gan.GYUNG:
    case Gan.SIN:
    case Ji.SIN:
    case Ji.YU:
      return OhHang.GEUM

    case Gan.IM:
    case Gan.GYE:
    case Ji.JA:
    case Ji.HAE:
      return OhHang.SOO

    case undefined:
      return OhHang.NULL

    default:
      return OhHang.TO
  }
}
