import React from 'react'
import {OneTimeBox} from './one-time-box'
import {oneDayContainer, date} from './one-day.module.scss'
import {DaySuccintInfos} from '../../../types/pickDay/DaySuccintInfos'
import moment from 'moment-timezone'
import {ONE_HOUR_IN_MILLI} from '../../../constants'
import {TotalScores} from '../../../types/saJoo/SuccinctInfo'
import {getTotalScore} from '../../pick-birthday/utils'
import {EumYang} from '../../../enums/EumYang'

interface Props {
  data: DaySuccintInfos
  onClick: (
    time: number,
    radar: number[],
    ohHang: number[],
    review: string[],
    boy: TotalScores,
    girl: TotalScores,
  ) => void
}

export const OneDayContainer: React.FC<Props> = ({data, onClick}) => {
  return (
    <div className={oneDayContainer}>
      <div className={date}>
        {moment.tz(data.day + ONE_HOUR_IN_MILLI, 'Asia/Seoul').format('MM/DD')}
      </div>
      {data.data.map((val, index) => (
        <OneTimeBox
          key={index}
          onClick={() => {
            // console.log(val);
            const boy = getTotalScore(
              val.saJooPalJa,
              val.ohHangSibSungs,
              val.ohHang,
            )
            const girl = getTotalScore(
              {...val.saJooPalJa, sex: EumYang.EUM},
              val.ohHangSibSungs,
              val.ohHang,
            )

            onClick(
              val.saJooPalJa.birthDay,
              val.radar,
              val.ohHang,
              val.review,
              boy,
              girl,
            )
          }}
          time={val.saJooPalJa.birthDay}
          score={average(val.radar)}
        />
      ))}
    </div>
  )
}

function average(val: number[]): number {
  return val.reduce((acc, cur) => acc + cur) / val.length
}
