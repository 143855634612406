import {SaJooPalJaForClient} from '../../types/SaJooPalJaForClient'
import {Gan} from '../../enums/Gan'
import {Ji} from '../../enums/Ji'
import moment, {Moment} from 'moment-timezone'
import {ONE_YEAR_IN_MILLISECONDS} from '../../constants'
import {EumYang} from '../../enums/EumYang'
import {checkWangJi, getEumYang, getSibSung} from '../../functions/core'
import {getOhHang} from '../../functions/core/getOhHang'
import {OhHang} from '../../enums/OhHang'
import {GanChoongType} from '../../enums/GanChoongType'
import {TotalScores} from '../../types/saJoo/SuccinctInfo'
import {SibSung} from '../../enums/SibSung'

export function getTotalScore(
  saJooPalJa: SaJooPalJaForClient,
  ohHangSibSungs: string[],
  ohHangPoints: number[],
): TotalScores {
  const y80sortedOhHangPoints = get80yearSortedOhHangPoint(
    saJooPalJa,
    ohHangPoints,
    ohHangSibSungs,
  )
  return {
    egoScores: get80yearsScores(y80sortedOhHangPoints, 3, 4, 0),
    actScores: get80yearsScores(y80sortedOhHangPoints, 4, 0, 1),
    wealthScores: get80yearsScores(y80sortedOhHangPoints, 0, 1, 2),
    honorScores: get80yearsScores(y80sortedOhHangPoints, 1, 2, 3),
    studyScores: get80yearsScores(y80sortedOhHangPoints, 2, 3, 4),
  }
}

function get80yearsScores(
  yearAndSortedOhHangPoints: TimeAndSortedOhHangPoint[],
  i: number,
  j: number,
  k: number,
): [number, number][] {
  const result: [number, number][] = []
  yearAndSortedOhHangPoints.forEach(({time, sortedOhHangPoint}) => {
    result.push([
      time,
      getYunWoonScore(
        sortedOhHangPoint[i],
        sortedOhHangPoint[j],
        sortedOhHangPoint[k],
      ),
    ])
  })

  return result
}

function getOhHangSibSungGroup(ilGan: Gan): string[] {
  const ohHangs = [Gan.GAB, Gan.BYUNG, Gan.MU, Gan.GYUNG, Gan.IM]
  return ohHangs
    .map((gan) => getSibSung(ilGan, gan))
    .map(sibSungToSibSungGroupLangCode)
}

function sibSungToSibSungGroupLangCode(sibSung: SibSung): string {
  switch (sibSung) {
    case SibSung.NULL:
      return ''
    case SibSung.ME:
    case SibSung.BI_GYUN:
    case SibSung.GUB_JAE:
      return 'SIBSUNG_BIGUB'
    case SibSung.SIK_SIN:
    case SibSung.SANG_GWAN:
      return 'SIBSUNG_SIKSANG'
    case SibSung.JUNG_JAE:
    case SibSung.PYUN_JAE:
      return 'SIBSUNG_JAESUNG'
    case SibSung.JUNG_GWAN:
    case SibSung.PYUN_GWAN:
      return 'SIBSUNG_GWANSUNG'
    case SibSung.JUNG_IN:
    case SibSung.PYUN_IN:
      return 'SIBSUNG_INSUNG'
  }
}

function getYunWoonScore(a: number, b: number, c: number): number {
  const btoc = Math.min(b, c)
  const atoc = Math.min(b, c, a)
  const ahitc = Math.max(a - b, 0)
  const coverb = Math.max(c - b, 0)
  const remain = coverb - ahitc
  const result = atoc + btoc + remain / 2
  return result
}

function get80yearSortedOhHangPoint(
  saJooPalJa: SaJooPalJaForClient,
  wonGookOhHangPoints: number[],
  ohHangSibSungs: string[],
): TimeAndSortedOhHangPoint[] {
  const result: TimeAndSortedOhHangPoint[] = []
  const {birthDay} = saJooPalJa
  const year = parseInt(moment.tz(birthDay, 'Asia/Seoul').format('YYYY'))
  const wonGookGans = getGanArrayFromSaJooPalJa(saJooPalJa)
  const wonGookJis = getJiArrayFromSaJooPalJa(saJooPalJa)
  const chunGanPointFactor = getWonGookOhHangPointFactor().chunGan
  const jiJiPointFactor = getWonGookOhHangPointFactor().jiJi
  const yunGanPoint = chunGanPointFactor[3]
  const yunJiPoint = jiJiPointFactor[3]
  const daeWoonGanPoint = chunGanPointFactor[4]
  const daeWoonJiPoint = jiJiPointFactor[4]
  for (let n = 10; n < 90; n++) {
    const ohHangPoints = [...wonGookOhHangPoints]
    const time = getMayFirstOfYear(year + n)
    const {yunGan, yunJi} = getYunJoo(saJooPalJa.yunGan, saJooPalJa.yunJi, n)
    const [daeWoonGan, daeWoonJi] = getCurrentDaewoon(time, saJooPalJa)
    ohHangPoints[getOhHangIndex(getOhHang(yunGan))] += yunGanPoint
    ohHangPoints[getOhHangIndex(getOhHang(daeWoonGan))] += daeWoonGanPoint
    ohHangPoints[getOhHangIndex(getOhHang(yunJi))] += yunJiPoint
    ohHangPoints[getOhHangIndex(getOhHang(daeWoonJi))] += daeWoonJiPoint
    const woonSeHabHwaOhHang = getWoonSeHabHwaOhHangPoint(
      [yunGan, daeWoonGan],
      [yunJi, daeWoonJi],
      wonGookGans,
      wonGookJis,
    )
    const woonSeChoongOhHang = getWoonSeChoongOhHangPoint(
      [yunGan, daeWoonGan],
      [yunJi, daeWoonJi],
      wonGookGans,
      wonGookJis,
    )
    ohHangPoints.forEach((val, index) => {
      ohHangPoints[index] =
        val + woonSeHabHwaOhHang[index] - woonSeChoongOhHang[index]
    })
    const sortedOhHangPoint = getSortedSibSungPoint(
      ohHangSibSungs,
      ohHangPoints,
    )
    result.push({
      time: time.toDate().getTime(),
      sortedOhHangPoint: sortedOhHangPoint,
    })
  }

  return result
}

function getYunJoo(yunGan: Gan, yunJi: Ji, n: number) {
  const chunGanElapse = n % 10
  const jiJiElapse = n % 12
  return {
    yunGan: getGanInRange(yunGan + chunGanElapse),
    yunJi: getJiInRange(yunJi + jiJiElapse),
  }
}

function getEnumInRange(
  target: number,
  minimum: number,
  maximum: number,
): number {
  const size = maximum - minimum + 1
  if (target < minimum) return target + size
  if (target > maximum) return target - size

  return target
}

const getGanInRange = (gan: Gan) => getEnumInRange(gan, Gan.GAB, Gan.GYE)
const getJiInRange = (ji: Ji) => getEnumInRange(ji, Ji.JA, Ji.HAE)

interface TimeAndSortedOhHangPoint {
  time: number
  sortedOhHangPoint: number[]
}

function getGanArrayFromSaJooPalJa(saJooPalJa: SaJooPalJaForClient): Gan[] {
  return [
    saJooPalJa.siGan,
    saJooPalJa.ilGan,
    saJooPalJa.wolGan,
    saJooPalJa.yunGan,
  ]
}

function getJiArrayFromSaJooPalJa(saJooPalJa: SaJooPalJaForClient): Ji[] {
  return [saJooPalJa.siJi, saJooPalJa.ilJi, saJooPalJa.wolJi, saJooPalJa.yunJi]
}

function getWonGookOhHangPointFactor(): Factor {
  return WonGookOhHangFactor
}

interface Factor {
  chunGan: number[]
  jiJi: number[]
  choGi: number[]
  joongGi: number[]
  bonGi: number[]
}

const WonGookOhHangFactor = {
  // chunGan: [10, 10, 10, 10, 10, 10, 10, 10, 10],
  // jiJi:    [15, 10, 15, 10, 20, 20, 15, 30, 10],
  chunGan: [16, 16, 16, 16, 16, 20, 20, 20, 16],
  jiJi: [16, 16, 16, 16, 24, 20, 20, 28, 16],
  choGi: [0, 0, 0, 0, 0, 0, 0, 0, 0],
  joongGi: [0, 0, 0, 0, 0, 0, 0, 0, 0],
  bonGi: [0, 0, 0, 0, 0, 0, 0, 0, 0],
}

function getMayFirstOfYear(year: number): Moment {
  return moment([year, 4, 1])
}

function getCurrentDaewoon(
  now: Moment,
  saJooPalJa: SaJooPalJaForClient,
): [Gan, Ji] {
  // 현재 시간을 밀리초로 표시
  const nowInMilli: number = now.toDate().getTime()

  // 태어난 시간을 밀리초로 표시
  const birthTImeInMilli: number = saJooPalJa.birthDay

  // 사주 팔자의 대운수
  const daewoonSoo = saJooPalJa.daeWoonSoo

  // 만 나이
  const elapsedYears =
    (nowInMilli - birthTImeInMilli) / ONE_YEAR_IN_MILLISECONDS

  // 만 나이가 대운수보다 작으면 월주를 리턴 한다.
  if (elapsedYears < daewoonSoo) {
    return [saJooPalJa.wolGan, saJooPalJa.wolJi]
  }

  // 대운수가 지난 후부터 몇 번의 10년이 지나갔는지 본다.
  const elapsedDaewoon: number = Math.floor((elapsedYears - daewoonSoo) / 10)

  // 천간 대운 열을 구한다.
  const daewoonGans = getDaeWoonGanArray(saJooPalJa).reverse()
  // 지지 대운 열을 구한다.
  const daewoonJis = getDaeWoonJiArray(saJooPalJa).reverse()
  // 현재에 해당하는 대운 간지를 리턴한다.
  return [daewoonGans[elapsedDaewoon], daewoonJis[elapsedDaewoon]]
}

function getDaeWoonGanArray(saJooPalJa: SaJooPalJaForClient): Gan[] {
  // 순행인지 역행인지 판단한다.
  const isSoonHang =
    (saJooPalJa.sex === EumYang.YANG &&
      getEumYang(saJooPalJa.yunGan) === EumYang.YANG) ||
    (saJooPalJa.sex === EumYang.EUM &&
      getEumYang(saJooPalJa.yunGan) === EumYang.EUM)
  const result: Gan[] = []

  if (isSoonHang) {
    for (let i = 1; i <= 10; i++) result.push(ganFilter(saJooPalJa.wolGan + i))
  } else {
    for (let i = 1; i <= 10; i++) result.push(ganFilter(saJooPalJa.wolGan - i))
  }
  return result.reverse()
}

function getDaeWoonJiArray(saJooPalJa: SaJooPalJaForClient): Ji[] {
  // 순행인지 역행인지 판단한다.
  const isSoonHang =
    (saJooPalJa.sex === EumYang.YANG &&
      getEumYang(saJooPalJa.yunGan) === EumYang.YANG) ||
    (saJooPalJa.sex === EumYang.EUM &&
      getEumYang(saJooPalJa.yunGan) === EumYang.EUM)

  const result: Ji[] = []

  if (isSoonHang) {
    for (let i = 1; i <= 10; i++) result.push(jiFilter(saJooPalJa.wolJi + i))
  } else {
    for (let i = 1; i <= 10; i++) result.push(jiFilter(saJooPalJa.wolJi - i))
  }
  return result.reverse()
}

// 천간 값이 10이 넘어가거나 1보다 작아지면 1~10 사이의 수로 재조정해준다.
function ganFilter(gan: number): Gan {
  if (gan > Gan.GYE) return gan - 10
  else if (gan < Gan.GAB) return gan + 10

  return gan
}

// 간지 값이 22가 넘어가거나 11보다 작아지면 11~22 사이의 수로 재조정해준다.
function jiFilter(ji: number): Ji {
  if (ji > Ji.HAE) return ji - 12
  else if (ji < Ji.JA) return ji + 12

  return ji
}

function getOhHangIndex(ohHang: OhHang): number {
  switch (ohHang) {
    case OhHang.MOK:
      return 0
    case OhHang.HWA:
      return 1
    case OhHang.TO:
      return 2
    case OhHang.GEUM:
      return 3
    case OhHang.SOO:
      return 4
    default:
      return 0
  }
}

function getWoonSeHabHwaOhHangPoint(
  woonGans: Gan[],
  woonJis: Ji[],
  wonGookGans: Gan[],
  wonGookJis: Ji[],
): number[] {
  const woonSeHabHwaOhHangParam: any = getWoonSeHabHwaOhHangPointParams()
  const result = [0, 0, 0, 0, 0]
  const length = wonGookGans.length
  woonGans.forEach((gan, index) => {
    const point = getOneWoonSeGanHabHwaOhHangPoint(
      gan,
      wonGookGans,
      woonSeHabHwaOhHangParam['ganHab'][5 - length + index],
    )
    for (let i = 0; i < 5; i++) {
      result[i] += point[i]
    }
  })

  woonJis.forEach((ji, index) => {
    const point = getOneWoonSeJiHabHwaOhHangPoint(
      ji,
      wonGookJis,
      woonSeHabHwaOhHangParam['samHab'][5 - length + index],
      checkSamBanHab,
    )
    for (let i = 0; i < 5; i++) {
      result[i] += point[i]
    }
  })

  woonJis.forEach((ji, index) => {
    const point = getOneWoonSeJiHabHwaOhHangPoint(
      ji,
      wonGookJis,
      woonSeHabHwaOhHangParam['bangHab'][5 - length + index],
      checkBangBanHab,
    )
    for (let i = 0; i < 5; i++) {
      result[i] += point[i]
    }
  })

  woonJis.forEach((ji, index) => {
    const point = getOneWoonSeJiHabHwaOhHangPoint(
      ji,
      wonGookJis,
      woonSeHabHwaOhHangParam['youkHab'][5 - length + index],
      checkYoukHab,
    )
    for (let i = 0; i < 5; i++) {
      result[i] += point[i]
    }
  })

  woonJis.forEach((ji, index) => {
    const point = getOneWoonSeJiHabHwaOhHangPoint(
      ji,
      wonGookJis,
      woonSeHabHwaOhHangParam['amHab'][5 - length + index],
      checkAmHab,
    )
    for (let i = 0; i < 5; i++) {
      result[i] += point[i]
    }
  })

  return result
}

function getWoonSeHabHwaOhHangPointParams(): object {
  return WoonSeHabHwaOhHangPointParams
}

const WoonSeHabHwaOhHangPointParams = {
  ganHab: [4, 4, 4, 4, 4],
  samHab: [4, 4, 4, 4, 4],
  bangHab: [4, 4, 4, 4, 4],
  youkHab: [4, 4, 4, 4, 4],
  amHab: [4, 4, 4, 4, 4],
}

function getOneWoonSeGanHabHwaOhHangPoint(
  gan: Gan,
  wonGookGans: Gan[],
  point: number,
): number[] {
  const result = [0, 0, 0, 0, 0]
  wonGookGans.forEach((wonGookGan) => {
    const habHwaOhHang = checkGanHab(gan, wonGookGan)
    if (habHwaOhHang !== OhHang.NULL) {
      result[getOhHangIndex(habHwaOhHang)] += point
    }
  })
  return result
}

function checkGanHab(first: Gan, second: Gan): OhHang {
  switch (first) {
    case Gan.GAB:
      if (second == Gan.GI) return OhHang.TO
      else return OhHang.NULL
    case Gan.EUL:
      if (second == Gan.GYUNG) return OhHang.GEUM
      else return OhHang.NULL

    case Gan.BYUNG:
      if (second == Gan.SIN) return OhHang.SOO
      else return OhHang.NULL

    case Gan.JUNG:
      if (second == Gan.IM) return OhHang.MOK
      else return OhHang.NULL

    case Gan.MU:
      if (second == Gan.GYE) return OhHang.HWA
      else return OhHang.NULL

    case Gan.GI:
      if (second == Gan.GAB) return OhHang.TO
      else return OhHang.NULL

    case Gan.GYUNG:
      if (second == Gan.EUL) return OhHang.GEUM
      else return OhHang.NULL

    case Gan.SIN:
      if (second == Gan.BYUNG) return OhHang.SOO
      else return OhHang.NULL

    case Gan.IM:
      if (second == Gan.JUNG) return OhHang.MOK
      else return OhHang.NULL

    case Gan.GYE:
      if (second == Gan.MU) return OhHang.HWA
      else return OhHang.NULL
  }

  return OhHang.NULL
}

function getOneWoonSeJiHabHwaOhHangPoint(
  ji: Ji,
  wonGookJis: Ji[],
  point: number,
  checkFunction: (a: Ji, b: Ji) => OhHang,
): number[] {
  const result = [0, 0, 0, 0, 0]
  wonGookJis.forEach((wonGookJi) => {
    const habHwaOhHang = checkFunction(ji, wonGookJi)
    if (habHwaOhHang !== OhHang.NULL) {
      result[getOhHangIndex(habHwaOhHang)] += point
    }
  })
  return result
}

function checkSamBanHab(first: Ji, second: Ji): OhHang {
  const isFirstWangJi = checkWangJi(first)
  const isSecondWangJi = checkWangJi(second)

  if (isFirstWangJi && !isSecondWangJi) {
    return getHabHawOhHang(first, second)
  } else if (!isFirstWangJi && isSecondWangJi) {
    return getHabHawOhHang(second, first)
  }

  return OhHang.NULL
}

function getHabHawOhHang(wangJi: Ji, sangOrGo: Ji): OhHang {
  switch (wangJi) {
    case Ji.JA: {
      switch (sangOrGo) {
        case Ji.SIN:
        case Ji.JIN:
          return OhHang.SOO
      }
      break
    }

    case Ji.OH: {
      switch (sangOrGo) {
        case Ji.IN:
        case Ji.SUL:
          return OhHang.HWA
      }
      break
    }

    case Ji.MYO: {
      switch (sangOrGo) {
        case Ji.HAE:
        case Ji.MI:
          return OhHang.MOK
      }
      break
    }

    case Ji.YU: {
      switch (sangOrGo) {
        case Ji.SA:
        case Ji.YU:
          return OhHang.GEUM
      }
      break
    }
  }
  return OhHang.NULL
}

function checkBangBanHab(first: Ji, second: Ji): OhHang {
  const isFirstWangJi = checkWangJi(first)
  const isSecondWangJi = checkWangJi(second)

  if (isFirstWangJi && !isSecondWangJi) {
    return getHabHawOhHang(first, second)
  } else if (!isFirstWangJi && isSecondWangJi) {
    return getHabHawOhHang(second, first)
  }

  return OhHang.NULL
}

function checkYoukHab(first: Ji, second: Ji): OhHang {
  switch (first) {
    case Ji.JA: {
      if (second == Ji.CHUK) return OhHang.TO
      else return OhHang.NULL
    }

    case Ji.CHUK: {
      if (second == Ji.JA) return OhHang.TO
      else return OhHang.NULL
    }

    case Ji.IN: {
      if (second == Ji.HAE) return OhHang.MOK
      else return OhHang.NULL
    }

    case Ji.MYO: {
      if (second == Ji.SUL) return OhHang.HWA
      else return OhHang.NULL
    }

    case Ji.JIN: {
      if (second == Ji.YU) return OhHang.GEUM
      else return OhHang.NULL
    }

    case Ji.SA: {
      if (second == Ji.SIN) return OhHang.SOO
      else return OhHang.NULL
    }

    case Ji.OH: {
      if (second == Ji.MI) return OhHang.HWA
      else return OhHang.NULL
    }

    case Ji.MI: {
      if (second == Ji.OH) return OhHang.HWA
      else return OhHang.NULL
    }

    case Ji.SIN: {
      if (second == Ji.SA) return OhHang.SOO
      else return OhHang.NULL
    }

    case Ji.YU: {
      if (second == Ji.JIN) return OhHang.GEUM
      else return OhHang.NULL
    }

    case Ji.SUL: {
      if (second == Ji.MYO) return OhHang.HWA
      else return OhHang.NULL
    }

    case Ji.HAE: {
      if (second == Ji.IN) return OhHang.MOK
      else return OhHang.NULL
    }
  }

  return OhHang.NULL
}

function checkAmHab(first: Ji, second: Ji): OhHang {
  switch (first) {
    case Ji.JA: {
      if (second == Ji.SUL || second == Ji.JIN) return OhHang.HWA
      else return OhHang.NULL
    }

    case Ji.CHUK: {
      if (second == Ji.IN) return OhHang.TO
      else return OhHang.NULL
    }

    case Ji.IN: {
      if (second == Ji.CHUK) return OhHang.TO
      if (second == Ji.MI) return OhHang.TO
      else return OhHang.NULL
    }

    case Ji.MYO: {
      if (second == Ji.SIN) return OhHang.GEUM
      else return OhHang.NULL
    }

    case Ji.JIN: {
      if (second == Ji.JA) return OhHang.HWA
      else return OhHang.NULL
    }

    case Ji.SA: {
      if (second == Ji.YU) return OhHang.SOO
      else return OhHang.NULL
    }

    case Ji.OH: {
      if (second == Ji.HAE) return OhHang.MOK
      else return OhHang.NULL
    }

    case Ji.MI: {
      if (second == Ji.IN) return OhHang.TO
      else return OhHang.NULL
    }

    case Ji.SIN: {
      if (second == Ji.MYO) return OhHang.GEUM
      else return OhHang.NULL
    }

    case Ji.YU: {
      if (second == Ji.SA) return OhHang.SOO
      else return OhHang.NULL
    }

    case Ji.SUL: {
      if (second == Ji.JA) return OhHang.HWA
      else return OhHang.NULL
    }

    case Ji.HAE: {
      if (second == Ji.OH) return OhHang.MOK
      else return OhHang.NULL
    }
  }

  return OhHang.NULL
}

function getWoonSeChoongOhHangPoint(
  woonGans: Gan[],
  woonJis: Ji[],
  wonGookGans: Gan[],
  wonGookJis: Ji[],
): number[] {
  const woonSeChoongHyungHaePaPointParam: any = getWoonSeChoongHyungHaePaPointParams()
  const result = [0, 0, 0, 0, 0]
  const length = wonGookGans.length

  woonGans.forEach((gan, index) => {
    const point = getOneWoonSeGanChoongOhHangPoint(
      gan,
      wonGookGans,
      woonSeChoongHyungHaePaPointParam['ganChoong'][5 - length + index],
    )
    for (let i = 0; i < 5; i++) {
      result[i] += point[i]
    }
  })
  woonJis.forEach((ji, index) => {
    const point = getOneWoonSeJiChoongOhHangPoint(
      ji,
      wonGookJis,
      woonSeChoongHyungHaePaPointParam['jiChoong'][5 - length + index],
    )
    for (let i = 0; i < 5; i++) {
      result[i] += point[i]
    }
  })

  return result
}

function getWoonSeChoongHyungHaePaPointParams(): object {
  return WoonSeChoongHyungHaePaOhHangPointParams
}

const WoonSeChoongHyungHaePaOhHangPointParams = {
  ganChoong: [10, 10, 10, 10, 10],
  jiChoong: [10, 10, 10, 10, 10],
  hyung: [0, 0, 0, 0, 0],
  hae: [0, 0, 0, 0, 0],
  pa: [0, 0, 0, 0, 0],
}

function getOneWoonSeGanChoongOhHangPoint(
  gan: Gan,
  wonGookGans: Gan[],
  point: number,
): number[] {
  const result = [0, 0, 0, 0, 0]
  wonGookGans.forEach((wonGookGan) => {
    if (checkGanChoong(wonGookGan, gan) !== GanChoongType.NULL) {
      result[getOhHangIndex(getOhHang(gan))] += point
      result[getOhHangIndex(getOhHang(wonGookGan))] += point
    }
  })
  return result
}

function checkGanChoong(first: Gan, second: Gan): GanChoongType {
  switch (first) {
    case Gan.GAB:
      if (second == Gan.GYUNG) return GanChoongType.BIG
      break

    case Gan.EUL:
      if (second == Gan.SIN) return GanChoongType.BIG
      break

    case Gan.BYUNG:
      if (second == Gan.IM) return GanChoongType.BIG
      break

    case Gan.JUNG:
      if (second == Gan.GYE) return GanChoongType.BIG
      break

    case Gan.GYUNG:
      if (second == Gan.GAB) return GanChoongType.SMALL
      break

    case Gan.SIN:
      if (second == Gan.EUL) return GanChoongType.SMALL
      break

    case Gan.IM:
      if (second == Gan.BYUNG) return GanChoongType.SMALL
      break

    case Gan.GYE:
      if (second == Gan.JUNG) return GanChoongType.SMALL
      break
  }

  return GanChoongType.NULL
}

function getOneWoonSeJiChoongOhHangPoint(
  ji: Ji,
  wonGookJis: Ji[],
  point: number,
): number[] {
  const result = [0, 0, 0, 0, 0]
  wonGookJis.forEach((wonGookJi) => {
    if (checkJiChoong(wonGookJi, ji) === true) {
      result[getOhHangIndex(getOhHang(ji))] += point
      result[getOhHangIndex(getOhHang(wonGookJi))] += point
    }
  })
  return result
}

function checkJiChoong(first: Ji, second: Ji): boolean {
  return Math.abs(first - second) == 6
}

function getSortedSibSungPoint(sibSungs: string[], points: number[]): number[] {
  let biGubIndex = 0
  sibSungs.forEach((val, index) => {
    if (val == 'SIBSUNG_BIGUB') {
      biGubIndex = index
    }
  })
  const result: number[] = [0, 0, 0, 0, 0]

  for (let i = 0; i < 5; i++) {
    result[i] = points[getEnumInRange(biGubIndex + i, 0, 4)]
  }

  return result
}
