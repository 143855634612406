export enum UnSung {
  NULL,
  JUL,
  TAE,
  YANG,
  JANG_SAENG,
  MOK_YOK,
  GWAN_DAE,
  GUN_ROK,
  JAE_WANG,
  SOE,
  BYUNG,
  SA,
  MYO,
}
